import { createSlice } from "@reduxjs/toolkit"

export const JobMatchingStatus = {
  NOT_PERFORM_MATCHING_YET: "not_perform_matching_yet",
  NO_MATCHING_RESULT: "no_matching_result",
  MATCHED: "matched",
  MATCHING_IN_PROGRESS: "matching_in_progress",
}

const initialState = {
  jobId: null,
  requirements: {},
  filteredRequirements: {},
  qa: {},
  candidates: null,
  jobStatus: null,
  jobMatchingStatus: null,
  areRequirementsSent: false,
  title: "",
  jobSpec: "",
  selectedCVs: [],
  jobs: null,
  alreadyMatchedOnce: false,
  noRequirementsSet: false,
  weights: {
    hard_skills_weight: 0.25,
    soft_skills_weight: 0.25,
    education_weight: 0.25,
    experience_weight: 0.25,
  },
  atsJobPosts: null,
}

const jobSlice = createSlice({
  name: "job",
  initialState,
  reducers: {
    setTitle: (state, action) => {
      state.title = action.payload
      state.jobId = null
    },
    setJobSpec: (state, action) => {
      state.jobSpec = action.payload
      state.jobId = null
    },
    setRequirements: (state, action) => {
      const { requirements, jobId } = action.payload
      state.requirements = requirements
      state.jobId = jobId
      state.candidates = []
      state.areRequirementsSent = false
    },
    setAreRequirementsSend: (state, action) => {
      state.areRequirementsSent = action.payload
    },
    setCandidates: (state, action) => {
      const { candidates } = action.payload
      state.candidates = candidates
    },
    setJobStatus: (state, action) => {
      state.jobStatus = action.payload
    },
    setJobMatchingStatus: (state, action) => {
      state.jobMatchingStatus = action.payload
    },
    setSelectedCVs: (state, action) => {
      state.selectedCVs = action.payload
    },
    setJobs: (state, action) => {
      state.jobs = action.payload
    },
    setAtsJobPosts: (state, action) => {
      state.atsJobPosts = action.payload
    },
    setJobQa: (state, action) => {
      state.qa = action.payload
    },
    setAlreadyMatchedOnce: (state, action) => {
      state.alreadyMatchedOnce = action.payload
    },
    setNoRequirementsSet: (state, action) => {
      state.noRequirementsSet = action.payload
    },
    setWeights: (state, action) => {
      const keys = Object.keys(action.payload)
      const obj = {
        ...state.weights,
      }
      keys.forEach((key) => {
        obj[key] = Number(action.payload[key])
      })
      state.weights = obj
    },
    resetJobSlice: (state) => {
      Object.keys(initialState).forEach((key) => {
        state[key] = initialState[key]
      })
    },
  },
})

export const {
  setTitle,
  setJobSpec,
  setRequirements,
  setCandidates,
  setAreRequirementsSend,
  setSelectedCVs,
  setJobs,
  setJobStatus,
  setAtsJobPosts,
  resetJobSlice,
  setJobQa,
  setAlreadyMatchedOnce,
  setNoRequirementsSet,
  setJobMatchingStatus,
  setWeights,
} = jobSlice.actions
export default jobSlice.reducer
