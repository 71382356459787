import { createSlice } from "@reduxjs/toolkit"
import { v4 as uuidv4 } from 'uuid';

const initialState = {
  cvs: null,
}

const cvSlice = createSlice({
  name: "cv",
  initialState,
  reducers: {
    setCvs: (state, action) => {
      state.cvs = action.payload
    },
    addPendingCv: (state, action) => {
      state.cvs = [
        ...state.cvs,
        {
          id: uuidv4(),
          information: {
            file_name: action.payload,
          },
          status: "IN_PROGRESS",
        },
      ]
    },
  },
})

export const { setCvs, addPendingCv} = cvSlice.actions

export default cvSlice.reducer
